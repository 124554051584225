import React from "react";
import styled from "styled-components";
import isLocale from "date-fns/locale/is";
import { parseISO } from "date-fns";
import { Archive } from "@styled-icons/evil";
import { H1 } from "../layout/Typography";
import { Loading } from "../layout/Loading";
import { Documents } from "@styled-icons/entypo";
import { Lock } from "@styled-icons/boxicons-regular";
import {
  GetPropertiesForDashboardQuery,
  GetPropertiesForDashboardQueryVariables,
  DocumentAccessType,
} from "../../../types";
import { getHumanReadableType } from "../../utils/document";
import { format } from "date-fns";
import {
  CardActionButton,
  CaretDownIcon,
  CaretRightIcon,
  CollapsedName,
  TitleContainer,
  TitleLeft,
  TitleRight,
} from "./CardCommon";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import { setCollapseCard } from "../../store/layout/actions";
import { getPropertiesForDashboard } from "../../queries/properties";
import {
  Table,
  TableHeader,
  TableTr,
  TableTh,
  TableBody,
} from "../layout/Table";
import { CardContainer, InnerCard } from "../layout/CardCommon";
import { useQuery } from "@apollo/client";
import { Tooltip } from "../layout/Tooltip";

const LoadingCard = styled(Loading)`
  height: 200px;
`;

const Icon = styled(Archive)`
  color: #cecece;
  height: 52px;
  align-self: flex-start;
`;

const IconBrowse = styled(Documents)`
  color: #fff;
  height: 24px;
`;

const IconLock = styled(Lock)`
  color: #cecece;
  height: 24px;
`;

const BodyContainer = styled.div`
  padding: 15px;
`;

const CallToActionContainer = styled.div`
  text-align: right;
  margin-top: 24px;
`;

const LockIconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  width: 24px;
`;

const DocumentNameWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export type Props = {};

export const DocumentsCard: React.FC<Props> = () => {
  const dispatch = useDispatch();

  /* COLLAPSE */
  const { collapsed_card } = useSelector((state: AppState) => state.layout);
  const CARD_KEY = "DOCUMENTS_CARD";
  const DEFAULT_COLLAPSED = true;
  const CARD_COLLAPSED =
    collapsed_card[CARD_KEY] === undefined
      ? DEFAULT_COLLAPSED
      : collapsed_card[CARD_KEY];

  const history = useHistory();

  const documentsQuery = useQuery<
    GetPropertiesForDashboardQuery,
    GetPropertiesForDashboardQueryVariables
  >(getPropertiesForDashboard);

  const documents = documentsQuery.data?.documentsV2 || [];

  return (
    <CardContainer>
      <InnerCard>
        {!documentsQuery.loading ? (
          CARD_COLLAPSED ? (
            <>
              {/* Non-Collapsed Section, Showing everything */}
              <TitleContainer>
                <TitleLeft>
                  <Icon />
                  <CollapsedName>
                    <H1>Nýjustu skjölin</H1>
                    <p>
                      <b>{documents.length}</b> skjöl sýnileg
                    </p>
                  </CollapsedName>
                </TitleLeft>
                <TitleRight>
                  <Tooltip tooltipMessage="Loka gagnafleka">
                    <CaretDownIcon
                      aria-label="Stækka kort"
                      onClick={() => dispatch(setCollapseCard(CARD_KEY, false))}
                    />
                  </Tooltip>
                </TitleRight>
              </TitleContainer>
              <BodyContainer>
                {documents.length > 0 ? (
                  <>
                    <Table>
                      <TableHeader>
                        <TableTr>
                          <TableTh>Nafn á skjali</TableTh>
                          <TableTh>Flokkur</TableTh>
                          <TableTh className="hide-mobile">Dagsett</TableTh>
                        </TableTr>
                      </TableHeader>
                      <TableBody>
                        {documents.map((document, index) => (
                          <TableTr key={index}>
                            <TableTh>
                              <DocumentNameWrapper>
                                {document.accessType ===
                                  DocumentAccessType.Full && (
                                  <LockIconWrapper>
                                    <Tooltip
                                      inline={true}
                                      tooltipMessage="Skjal einungis sýnilegt fyrir íbúa með fullan aðgang"
                                    >
                                      <IconLock />
                                    </Tooltip>
                                  </LockIconWrapper>
                                )}
                                <div>
                                  <a href={`/file/${document.id}`}>
                                    {document.name}
                                  </a>
                                </div>
                              </DocumentNameWrapper>
                            </TableTh>
                            <TableTh>
                              {getHumanReadableType(document.type)}
                            </TableTh>
                            <TableTh className="hide-mobile">
                              {format(
                                parseISO(document.displayDate),
                                "d. MMMM yyyy",
                                {
                                  locale: isLocale,
                                }
                              )}
                            </TableTh>
                          </TableTr>
                        ))}
                      </TableBody>
                    </Table>
                  </>
                ) : (
                  <div>Engin skjöl til að birta.</div>
                )}
                <CallToActionContainer>
                  <CardActionButton
                    aria-label="Skoða gagnabankann"
                    buttonType="primary"
                    onClick={() => history.push("/documents")}
                  >
                    <IconBrowse /> Skoða gagnabankann
                  </CardActionButton>
                </CallToActionContainer>
              </BodyContainer>
            </>
          ) : (
            <div>
              <TitleContainer>
                <TitleLeft>
                  <Icon />
                  <CollapsedName>
                    <H1>Nýjustu skjölin</H1>
                    <p></p>
                  </CollapsedName>
                </TitleLeft>

                <TitleRight>
                  <Tooltip tooltipMessage="Opna gagnafleka">
                    <CaretRightIcon
                      aria-label="Hrynja saman korti"
                      onClick={() => dispatch(setCollapseCard(CARD_KEY, true))}
                    />
                  </Tooltip>
                </TitleRight>
              </TitleContainer>
            </div>
          )
        ) : (
          <LoadingCard loadingMessage="Hleð inn upplýsingum um skjöl" />
        )}
      </InnerCard>
    </CardContainer>
  );
};
