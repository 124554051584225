import { gql } from "@apollo/client";

export const getGetDocumentsV2 = gql`
  query GetDocumentsV2(
    $fromDate: String
    $toDate: String
    $limit: Int!
    $page: Int!
    $propertyId: String
  ) {
    documentsV2(
      limit: $limit
      page: $page
      filter: { fromDate: $fromDate, toDate: $toDate, propertyId: $propertyId }
    ) {
      id
      name
      type
      mimetype
      description
      createdDate
      modifiedDate
      displayDate
      accessType
    }
  }
`;
